import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Item, Popup } from 'semantic-ui-react';
import { InputButton } from '../../components/controls';
import { ActionProp, FieldProp, Filter, Loading, ModalPopup, ProviderSubHeader } from '../../components/shared';
import { GlobalContext } from '../../context/GlobalContext';
import { queryDownloadCSVAdministrators } from '../../services';
import { PAGE_LINKS } from '../../utilities';
import '../PatientStatus/PatientStatus.scss';
import { AdministratorsTable } from './AdministratorsTable';
import { AccountManagementContext } from './context/AccountManagementContext';

const filterByActiveStatus = [
  {
    value: 'all',
    text: 'Show All Admin Accounts',
    key: 1,
  },
  {
    value: 'active',
    text: 'Show Only Active Admin Accounts',
    key: 2,
  },
  {
    value: 'inactive',
    text: 'Show Only Deactivated Admin Accounts',
    key: 3,
  },
];

const pageHeaderTitle = [{ key: 'Administrators', content: 'administrators', active: true }];

const Administrators = () => {
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);

  const [searchOptions, setSearchOptions] = useState<{
    [k: string]: any;
  } | null>(null);

  const { roles } = useContext(GlobalContext);
  const accountManagementContext = useContext(AccountManagementContext);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [downloadCSVAdministrators, { error }] = useLazyQuery(queryDownloadCSVAdministrators, {
    fetchPolicy: 'no-cache',
  });

  const filterFields: FieldProp[] = useMemo(() => {
    return [
      {
        name: 'accountStatus',
        type: 'radioList',
        label: 'Filter By Account Status',
        option: filterByActiveStatus,
        skipTagging: true,
        addClasses: 'filter-label',
      },
      {
        name: 'name',
        type: 'textbox',
        label: 'Name',
        placeholder: 'Enter Name',
      },
      {
        name: 'email',
        type: 'textbox',
        label: 'Email',
        placeholder: 'Enter Email',
      },
    ];
  }, []);

  const filterActions: ActionProp[] = [
    {
      name: 'search',
      label: 'FILTER',
      addClasses: 'mb-0',
      onClick: (_, data) => {
        let searchQuery: { [k: string]: any } = {};
        Object.keys(data).forEach((key) => {
          if (data[key] !== '') {
            searchQuery[key] = data[key];
          }
        });
        setSearchParams(searchQuery);
      },
    },
    {
      name: 'clearfilter',
      label: 'Clear filters',
      addClasses: 'mb-0 btn-secondary',
      onClick: () => {
        setSearchParams({ accountStatus: 'active' });
      },
    },
  ];

  useEffect(() => {
    if (roles.length > 0) {
      accountManagementContext?.setUser(null);
      const params = Array.from(searchParams.entries());

      if (params.length === 0) {
        setSearchParams({ accountStatus: 'active' });
      }

      setIsComponentMounted(true);
    }
  }, [accountManagementContext, searchParams, setSearchParams, roles]);

  const pageHeaderActions = (
    <>
      <InputButton
        text="Export"
        onClick={async () => {
          setLoadingOnTableAction(true);
          downloadCSVAdministrators({
            variables: {
              searchOptions: { ...searchOptions },
            },
          }).then(({ data }) => {
            setLoadingOnTableAction(false);
            const file = new Blob([data.downloadAdministratorsCSV], {
              type: 'text/csv',
            });

            const fileUrl = URL.createObjectURL(file);
            const fileName = `Provider_Portal_Administrators_${moment(new Date()).format('YYYY-MM-DD HH:MM')}.csv`;

            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }}
      />

      <Popup
        content="Add a new user"
        trigger={
          <div>
            <InputButton icon="plus" circular size="mini" onClick={() => navigate(PAGE_LINKS.adminUserCreate)} />
          </div>
        }
      />
    </>
  );

  if (error) {
    console.log(error);
    setShowErrorModal(true);
  }

  return (
    <>
      <Item as="div" className="body-content">
        <ProviderSubHeader pageTitle={pageHeaderTitle} ActionButton={pageHeaderActions} />
        {!isComponentMounted ? (
          <Loading show={true} />
        ) : (
          <Container fluid className="with-sidebar">
            <Filter fields={filterFields} actions={filterActions} />
            <Item as="div" id="scrollTo" className="content">
              <AdministratorsTable onSearchOptionsChange={(selectedSearchOptions) => setSearchOptions(selectedSearchOptions)} />
            </Item>
          </Container>
        )}
      </Item>
      {isLoadingOnTableAction && <Loading show />}
      {showErrorModal && (
        <ModalPopup
          showPopup
          title="Error"
          body="Error occured while downloading the Providers, please try again.  If same error repeats, please reach support team."
          onClose={() => setShowErrorModal(false)}
        />
      )}
    </>
  );
};

export default Administrators;

import { useLazyQuery } from '@apollo/client';
import * as lodash from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Form, Grid, Item } from 'semantic-ui-react';
import { InputButton, InputField, InputSelect } from '../../../components/controls';
import RadioList from '../../../components/controls/form/RadioList/RadioList';
import { PPTable, SortDirectionProp, TableColumnProps } from '../../../components/controls/ProviderTable';
import { Loading } from '../../../components/shared';
import { GlobalContext } from '../../../context/GlobalContext';
import { usePrevious } from '../../../hooks';
import { queryProviderContactMissingInfos,queryDownloadSugarContactCSV } from '../../../services';
import { ProviderContact, ProviderContactMissingInfoWorklist } from '../../../types';
import { CsvFilter } from '../../../types/csv-filter.types';

import {
  DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION,
  SORT_ORDERS,
  TABLE_MISSING_INFO_PAGE_COUNTS,
  WORKLIST_TYPES,
} from '../../../utilities/constants';
import { formatDateToCustomString, transformSearchedParamEntries } from '../../../utilities/sharedFunctions';
import useProviderContactMissingInfoActiveStatusToggle from '../hooks/useProviderContactMissingInfoActiveStatusToggle';
import './ProviderContactMissingInfo.scss';
import moment from 'moment';

const SEARCH_FIELD_MAPPER = {
  providerName: 'providerName',
  providerBrightreeId: 'providerBrightreeId',
  providerNPI: 'providerNPI',
  sugarName: 'sugarName',
  sugarDocKey: 'sugarDocKey',
  isActive: 'isActive',
  open: 'open',
  closed: 'closed',
};

const SORT_FIELDS = {
  createdAt: 'createdAt',
};

const FILTER_BY_DROPDOWN_OPTIONS = {
  SUGAR_CONTACT_REQUIRED: [
    { text: 'BT Provider', value: SEARCH_FIELD_MAPPER.providerName },
    { text: 'BT Doc Key', value: SEARCH_FIELD_MAPPER.providerBrightreeId },
    { text: 'BT NPI', value: SEARCH_FIELD_MAPPER.providerNPI },
    { text: 'Sugar Contact', value: SEARCH_FIELD_MAPPER.sugarName },
  ],
  CONTACT_DETAILS_REQUIRED: [
    { text: 'BT Provider', value: SEARCH_FIELD_MAPPER.providerName },
    { text: 'Sugar Contact', value: SEARCH_FIELD_MAPPER.sugarName },
    { text: 'Sugar Doc Key', value: SEARCH_FIELD_MAPPER.sugarDocKey },
  ],
};
const FILTER_BY_RADIO_OPTIONS = [
  { text: 'Open', value: SEARCH_FIELD_MAPPER.open },
  { text: 'Closed', value: SEARCH_FIELD_MAPPER.closed },
];

type ProviderContactMissingInfoProps = {
  worklistType: string;
  note: string;
  onWorklistUpdate: (totalRecords?: number) => void;
};

const ProviderContactMissingInfo = ({ worklistType, note, onWorklistUpdate }: ProviderContactMissingInfoProps) => {
  const { logout } = useContext(GlobalContext);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const previousPropValues = usePrevious({ worklistType });

  const sortField = searchParams.get('__sort') || SORT_FIELDS.createdAt;
  const sortDirection = searchParams.get('__order') || SORT_ORDERS.descending;
  const currentPage = +(searchParams.get('__page') || 1);
  const recordToView = searchParams.get('isActive') === 'false' ? SEARCH_FIELD_MAPPER.closed : SEARCH_FIELD_MAPPER.open;

  const filteredItems = location.search
    ?.substring(1)
    .split('&')
    .filter((item) => !item.includes('__') && !item.includes('isActive'));

  const [filterKey, filterValue] = filteredItems.length > 0 ? filteredItems[0].split('=') : [null, null];

  const [selectedFilterByRadioOption, setSelectedFilterByRadioOption] = useState(recordToView);
  const [selectedFilterByDropdownOption, setSelectedFilterByDropdownOption] = useState(filterKey || SEARCH_FIELD_MAPPER.providerName);

  const [selectedFilterByFieldValue, setSelectedFilterByFieldValue] = useState(filterValue || '');

  const [isLoading, setLoading] = useState(true);

  const [apiCallTriggered, setAPICallTriggered] = useState(false);
  const [isLoadingOnTableAction, setLoadingOnTableAction] = useState(false);

  const { isActiveRecord, setActiveStatus } = useProviderContactMissingInfoActiveStatusToggle();

  const [getProviderContactMissingInfos, { data: apiGetData, error: apiGetError, loading: apiGetLoading }] = useLazyQuery(
    queryProviderContactMissingInfos,
    {
      fetchPolicy: 'no-cache', // No cache added as query depends on the variable, refresh query is not working here.
    },
  );
  const [getCSVForSugarContact] = useLazyQuery(
    queryDownloadSugarContactCSV,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const getSearchParams = ():CsvFilter=>{
    let sortFieldInfo = sortField === 'createdAt' ? 'CREATED_AT' : sortField;
    let sortDir = sortDirection === 'ascending' ? 'DESC' : 'ASC';

    const skip = (currentPage - 1) * DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION;

    let worklistTypeName = worklistType;

    if (location.pathname.includes('sugar')) {
      worklistTypeName = WORKLIST_TYPES.SUGAR_CONTACT_REQUIRED;
    }
    if (location.pathname.includes('contact-details')) {
      worklistTypeName = WORKLIST_TYPES.CONTACT_DETAILS_REQUIRED;
    }

    const filterOption: Record<string, any> = {
      isActive: true,
      worklistType: worklistTypeName,
    };

    const searchedParams = transformSearchedParamEntries(searchParams);

    Object.keys(searchedParams).forEach((key) => {
      if (!key.includes('__')) {
        if (key === 'isActive') {
          filterOption[key] = searchedParams[key] === 'false' ? false : true;
        } else {
          filterOption[key] = searchedParams[key];
        }
      }
    });

    return {
      skip: skip,
      take: DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION,
      sortBy: sortFieldInfo,
      sortDirection: sortDir,
      filter: filterOption,
    };
  };

  const getSugarContactingCSV = ()=>{
    const searchOptions = getSearchParams();
    searchOptions.take = 0;
    searchOptions.timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    getCSVForSugarContact({
      variables: {
        searchOptions: { ...searchOptions },   
      },
    }).then((response) => {    
  const data = response?.data?.downloadSugarContactCSV;
      const file = new Blob([data], {
        type: 'text/csv',
      });
      const fileUrl = URL.createObjectURL(file);
      let fileName = '';
      if (location.pathname.includes('sugar')) {
         fileName = `Provider_Portal_SUGAR_CONTACT_REQUIRED_${moment(new Date()).format('YYYY-MM-DD HH:MM')}.csv`;
      }
      if (location.pathname.includes('contact-details')) {
         fileName = `Provider_Portal_CONTACT_DETAILS_REQUIRED_${moment(new Date()).format('YYYY-MM-DD HH:MM')}.csv`;
      }
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return ;
  
    });
  };
  const handleActiveToggleClick = useCallback(
    async (id: number, isActive: boolean) => {
      setLoadingOnTableAction(true);
      await setActiveStatus(id, isActive);
      setLoadingOnTableAction(false);
    },
    [setActiveStatus],
  );

  const onColumnDataRender = useCallback(
    (column: TableColumnProps, rowData: ProviderContactMissingInfoWorklist) => {
      if (column.name === SEARCH_FIELD_MAPPER.providerName) {
        return (
          <>
            <div className="item cell">
              <span
                style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                onClick={() => window.open(rowData.brightreeNavigationLink, '_blank')}
              >
                {rowData.providerName || 'N/A'}
              </span>
            </div>
            <div>{rowData.providerGroup || 'N/A'}</div>
          </>
        );
      } else if (column.name === 'sugarProviderName') {
        return (
          <>
            {rowData.sugarContacts?.map((sugarContact: ProviderContact) => {
              if (sugarContact.sugarRecordId?.toUpperCase().includes('NOT_FOUND')) {
                return <div>A sugar contact with a matching NPI or Doc Key was not found.</div>;
              }

              return (
                <div key={sugarContact.sugarRecordId} className="sugarProviderName">
                  <div>
                    <span
                      style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                      onClick={() => window.open(sugarContact.sugarContactNavigationLink, '_blank')}
                    >
                      {sugarContact.sugarName || 'N/A'}
                    </span>
                  </div>
                </div>
              );
            })}
          </>
        );
      } else if (column.name === 'sugarAccountForContactDetailsRequired') {
        return (
          <>
            {rowData.sugarContacts?.map((sugarContact) => {
              return (
                <div key={sugarContact.sugarRecordId} className="sugarProviderName">
                  <div>
                    <span
                      style={{ cursor: 'pointer', color: '#eb5b28', fontWeight: 700 }}
                      onClick={() => window.open(sugarContact.sugarContactNavigationLink, '_blank')}
                    >
                      {sugarContact.sugarName || 'N/A'}
                    </span>
                  </div>
                  <div>
                    {lodash.startCase((sugarContact.preferredCommunicationMethod || '').toLowerCase()) || 'N/A'}:{' '}
                    {sugarContact.communicationMethodDetails || 'N/A'}
                  </div>
                </div>
              );
            })}
          </>
        );
      } else if (column.name === 'createdAt') {
        return formatDateToCustomString(rowData.createdAt, true);
      } else if (column.name === 'closedAt') {
        return !rowData.closedAt ? '' : formatDateToCustomString(rowData.closedAt, true);
      } else if (column.name === 'sugarDocKey') {
        return <span>{rowData.sugarContacts?.length > 0 ? rowData.sugarContacts[0].sugarDocKey : 'N/A'}</span>;
      }

      return (
        <Item className="actions-items">
          <InputButton
            size="mini"
            // addCssClasses={`btn-sm ${rowData.isActive ? 'active' : 'inactive'} `}
            inline={false}
            text={selectedFilterByRadioOption === 'closed' ? 'Reopen' : 'Close'}
            onClick={() => {
              handleActiveToggleClick(rowData.id, !rowData.isActive);
            }}
          />
        </Item>
      );
    },
    [handleActiveToggleClick, selectedFilterByRadioOption],
  );

  const tableColumns: TableColumnProps[] = useMemo(() => {
    const commonColumns = {
      providerName: {
        name: SEARCH_FIELD_MAPPER.providerName,
        label: `BT Provider <br />BT Provider Group`,
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
      },
      createdAt: {
        name: 'createdAt',
        label: 'Entered List',
        addClasses: 'width-08-percent',
        isSortable: true,
        onColumnDataRender: onColumnDataRender,
      },
      closedAt: {
        name: 'closedAt',
        label: 'Closed At',
        addClasses: `width-05-percent ${selectedFilterByRadioOption === 'closed' ? 'show' : 'hide'}`,
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
      },
      actions: {
        name: 'actions',
        label: '',
        isSortable: false,
        onColumnDataRender: onColumnDataRender,
      },
    };

    const columns: any = {
      SUGAR_CONTACT_REQUIRED: [
        { ...commonColumns.providerName },
        { name: 'providerBrightreeId', label: 'BT Doc Key', isSortable: false },
        { name: 'providerNPI', label: 'BT NPI', isSortable: false, addClasses: 'width-08-percent' },
        {
          name: 'sugarProviderName',
          label: 'Sugar Contact(s) with Matching NPI or Doc Key',
          isSortable: false,
          onColumnDataRender: onColumnDataRender,
        },
        { ...commonColumns.createdAt },
        { ...commonColumns.closedAt },
        { ...commonColumns.actions },
      ],
      CONTACT_DETAILS_REQUIRED: [
        { ...commonColumns.providerName },
        {
          name: 'sugarAccountForContactDetailsRequired',
          label: 'Sugar Contact<br />Contact Method: Details',
          isSortable: false,
          onColumnDataRender: onColumnDataRender,
        },
        { name: 'sugarDocKey', label: 'Sugar Doc Key', isSortable: false, onColumnDataRender: onColumnDataRender },
        { ...commonColumns.createdAt },
        { ...commonColumns.closedAt },
        { ...commonColumns.actions },
      ],
    };

    return columns[worklistType];
  }, [selectedFilterByRadioOption, onColumnDataRender, worklistType]);

  if (apiGetError) {
    const {
      graphQLErrors: [{ message }],
    } = apiGetError;

    if (message === 'Invalid token' || message === 'Unauthorized') {
      logout();
    }
  }

  const { totalRecords, providerContactMissingInfos } = apiGetData?.providerContactMissingInfos || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUpdatedProviderContactMissingInfos = () => {
    const searchOptions = getSearchParams();
    if (!apiCallTriggered) {
      setAPICallTriggered(true);
      getProviderContactMissingInfos({
        variables: {
          searchOptions: { ...searchOptions },
        },
      }).then((response) => {
        setAPICallTriggered(false);
        setLoadingOnTableAction(false);
        onWorklistUpdate(response?.data?.providerContactMissingInfos?.totalRecords);
      });
    }
  };

  useEffect(() => {
    if (Array.from(searchParams.entries()).length === 0) {
      setSelectedFilterByRadioOption(SEARCH_FIELD_MAPPER.open);
      setSelectedFilterByDropdownOption(SEARCH_FIELD_MAPPER.providerName);
      setSelectedFilterByFieldValue('');
    }

    getUpdatedProviderContactMissingInfos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isActiveRecord]);

  useEffect(() => {
    if (previousPropValues?.worklistType && previousPropValues?.worklistType !== worklistType) {
      if (Array.from(searchParams.entries()).length === 0) {
        setSelectedFilterByDropdownOption(SEARCH_FIELD_MAPPER.providerName);
        setSelectedFilterByFieldValue('');
        setSelectedFilterByRadioOption(SEARCH_FIELD_MAPPER.open);
      }
      getUpdatedProviderContactMissingInfos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worklistType]);

  const tableData = useMemo(() => {
    const retVal: ProviderContactMissingInfoWorklist[] = [];

    providerContactMissingInfos?.forEach((sugarContactRequiredRecord: any) => {
      const { provider, providerGroup, sugarContacts } = sugarContactRequiredRecord;

      const providerContactMissingInfoWorklist: ProviderContactMissingInfoWorklist = {
        id: provider.id,
        isActive: sugarContacts && sugarContacts.length > 0 ? sugarContacts[0].isActive : false,
        providerName: provider.name,
        providerGroup: providerGroup?.originalName || providerGroup?.name || 'N/A',

        providerBrightreeId: provider.brightreeId,
        providerNPI: provider.npi || 'N/A',
        sugarContacts,
        createdAt: sugarContacts && sugarContacts.length > 0 ? sugarContacts[0].createdAt : undefined,
        closedAt: sugarContacts && sugarContacts.length > 0 ? sugarContacts[0].closedAt : undefined,

        brightreeNavigationLink: provider.brightreeNavigationLink,
      };

      retVal.push(providerContactMissingInfoWorklist);
    });

    return retVal;
  }, [providerContactMissingInfos]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const handleSearch = () => {
    setSearchParams({
      [selectedFilterByDropdownOption]: selectedFilterByFieldValue,
      isActive: (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open ? true : false).toString(),
      __order: SORT_ORDERS.descending,
      __page: '1',
    });
  };

  const handleRestList = () => {
    setSearchParams({
      isActive: (selectedFilterByRadioOption === SEARCH_FIELD_MAPPER.open ? true : false).toString(),
      __order: SORT_ORDERS.descending,
      __page: '1',
    });

    setSelectedFilterByRadioOption(SEARCH_FIELD_MAPPER.open);
    setSelectedFilterByDropdownOption(SEARCH_FIELD_MAPPER.providerName);
    setSelectedFilterByFieldValue('');
  };

  return (
    <div className="provider-contact-missing-info-container">
      <Item as="p" className="desc">
        {note}
      </Item>

      <Grid className="filter-pane">
        <Grid.Row columns={2}>
          <Grid.Column mobile={16} computer={13}>
            <InputSelect
              key="filterBy"
              name="filterBy"
              inline={false}
              options={FILTER_BY_DROPDOWN_OPTIONS[worklistType as keyof typeof FILTER_BY_DROPDOWN_OPTIONS]}
              search={false}
              placeholder="Filter by:"
              selection={'selection'}
              label="Filter by:"
              value={selectedFilterByDropdownOption}
              onChange={(_, { value }) => {
                setSelectedFilterByDropdownOption(value + '');
              }}
            />
            <Form>
              <InputField
                type="string"
                name="filterByFieldValue"
                label=""
                placeholder={`Enter Value`}
                value={selectedFilterByFieldValue}
                maxLength={50}
                inline={false}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setSelectedFilterByFieldValue(value);
                }}
              />
              <div className="action-buttons">
                <InputButton
                  text="Filter"
                  inline={false}
                  addCssClasses="search-button"
                  disabled={!(selectedFilterByFieldValue + '').trim()}
                  onClick={handleSearch}
                />

                <InputButton text="Reset List" inline={false} addCssClasses="search-button" onClick={handleRestList} />
              </div>
            </Form>
          </Grid.Column>
          <Grid.Column mobile={16} computer={3} className="filter-controls">
     <Item style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', marginBottom: '-33px'}}>
          <InputButton
          inline={false}
          text={'CSV'}
          type='button'
          addCssClasses='download-csv'
          icon='download'
          onClick={(e) => {
            e.preventDefault();
            getSugarContactingCSV();
          }}
        />
        </Item>
            <RadioList
              name="statusFilter"
              label=""
              options={FILTER_BY_RADIO_OPTIONS}
              value={selectedFilterByRadioOption}
              onChange={(selectedValue) => {
                const existingSearchedParams = transformSearchedParamEntries(searchParams);

                setSearchParams({
                  ...existingSearchedParams,
                  __page: '1',
                  __order: SORT_ORDERS.descending,
                  isActive: selectedValue === 'closed' ? 'false' : 'true',
                });

                setSelectedFilterByRadioOption(selectedValue);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {isLoading || (apiGetLoading && !isLoadingOnTableAction) ? (
        <Loading show={true} />
      ) : (
        <PPTable
          className="pp-table-secondary align-text-bottom"
          data={tableData}
          columns={tableColumns}
          totalRecords={totalRecords}
          currentPage={currentPage}
          pageSize={DEFAULT_NUMBER_OF_RECORDS_PER_PAGE_MISSING_INFORMATION}
          sortField={sortField}
          sortDirection={sortDirection as SortDirectionProp} // Send value in opposite direction as requirement is to show icon in opposite direction
          onSort={(sortBy, sortDir) => {
            setLoadingOnTableAction(true);
            const existingSearchedParams = transformSearchedParamEntries(searchParams);

            setSearchParams({
              ...existingSearchedParams,
              __sort: sortBy,
              __order: sortDir,
            });
          }}
          onPageChange={(updatedCurrentPage) => {
            setLoadingOnTableAction(true);
            const existingSearchedParams = transformSearchedParamEntries(searchParams);

            setSearchParams({
              ...existingSearchedParams,
              __page: updatedCurrentPage.toString(),
            });
          }}
          pageCounts={TABLE_MISSING_INFO_PAGE_COUNTS}
        />
      )}
    </div>
  );
};

export default ProviderContactMissingInfo;

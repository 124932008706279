export const isValueInclude = (data: string[], fieldValue: string): boolean => {
  for (let val of data) {
    if (fieldValue.includes(val)) {
      return true;
    }
  }
  return false;
};

export enum DateFormat {
  'yyyy-mm-dd' = 'yyyy-mm-dd',
  'mm/dd/yyyy' = 'mm/dd/yyyy',
}

export const dateFormat = (input: string, format: DateFormat): string => {  
  const [year, month, date] = (input.includes(' ') ? input.split(' ')[0] : input.split('T')[0]).split('-');
  switch (format) {
    case 'yyyy-mm-dd':
      return `${year}-${month}-${date}`;
    default:
      return `${month}/${date}/${year}`;
  }
};

export const getColumnValue = (columnName: string, rowData: { [k: string]: any }) => {
  let retVal = '';
  if (columnName.includes('.')) {
    try {
      // eslint-disable-next-line no-eval
      retVal = eval(`rowData["${columnName.split('.').join('"]["')}"]`);
    } catch {}
  } else if (columnName.includes(' ')) {
    const columns = columnName.split(' ');
    columns.forEach((column) => {
      retVal += ` ${rowData[column]}`;
    });
  } else {
    retVal = rowData[columnName];
  }

  return retVal;
};

export function convertToPST(date: Date, shouldRemoveComma = false) {
  let retVal = new Date(date).toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

  if (shouldRemoveComma) {
    retVal = retVal.replace(',','');
  }

  return retVal;
}

export function formatDateToCustomString(date: Date | string, shouldRemoveComma = false) {
  let retVal = new Date(date).toLocaleString('en-US', {    
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });

  if (shouldRemoveComma) {
    retVal = retVal.replace(',','');
  }

  return retVal;
}

export function pageScrollTop(id: string) {
  const ele = document.getElementById(id) as HTMLElement;

  if (ele) {
    ele.scrollTop = 0;
  }
}

export function transformSearchedParamEntries (searchParams: URLSearchParams) {
  const params = Array.from(searchParams);
  const searchedParams: Record<string, string> = {};

  if (params.length > 0) {
    params.forEach((param) => {
      const [key, value] = param;
      searchedParams[key] = value;
    });
  }

  return searchedParams;
};

export const getDaysDifference = (dateString: string) => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();
  
  // Calculate the difference in milliseconds
  const differenceInTime = currentDate.getTime() - givenDate.getTime();
  
  // Convert the difference from milliseconds to days
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  
  return differenceInDays;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

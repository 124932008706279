import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Item } from 'semantic-ui-react';

interface DateRangePickerProps {
  name: string;
  defaultStartDate: Date | null;
  defaultEndDate: Date | null;
  onDateChange: (range: { startDate: Date | null; endDate: Date | null }) => void;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ name, defaultStartDate, defaultEndDate, onDateChange }) => {
  const [startDate, setStartDate] = useState<Date | null | undefined>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date | null | undefined>(defaultEndDate);

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (onDateChange) {
      onDateChange({ startDate: start, endDate: end });
    }
  };

  return (
    <Form.Field>
      <Item as="div">
        <DatePicker
          name={name}
          showIcon
          toggleCalendarOnIconClick
          yearDropdownItemNumber={100}
          placeholderText={'MM/dd/yyyy - MM/dd/yyyy'}
          className=""
          selected={startDate}
          onChange={handleDateChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
        />
      </Item>
    </Form.Field>
  );
};
